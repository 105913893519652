import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn: "https://f2995a78ae32054a299ef996b1af4946@o4507548598140928.ingest.de.sentry.io/4507548600107088",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
});
